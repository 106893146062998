import React, { Component } from "react";
import "./chat-box.scss";
import { cloudinaryImageUpload, getDynamicChatToken, uploadMedia } from "../../http-calls";
import Loader from "react-loader-spinner";
import { getChatToken } from "../../http-calls";
import { cloudinaryAutoUpload } from "../../http-calls";
import chatCodes from "../../config/chat-codes";

const $ = window.$;

class ChatBox extends Component {
  state = {
    messages: [],
    messageBoxText: "",
    unreadCount: 0,
    channelConnected: false,
    filesSelected: [],
    uploadQueue: 0,
  };

  chatClient = null;
  generalChannel = null;
  ownUsername = null;

  componentDidMount() {
    // Connect to the general channel
    this._connectToChannel();
  }

  _updateChannelStatus = (channelConnected) => {
    this.setState({ channelConnected }, () => {
      this._initializeChatWindow();
    });
  };

  _connectToChannel = async () => {
    try {
      let tokenResponse = null;
      const {isCustomTwilioCredsAvailable,
        TWILIO_ACCOUNT_SID,
        TWILIO_API_KEY,
        TWILIO_API_SECRET} = this.props;
        if (isCustomTwilioCredsAvailable) {
          tokenResponse = await getDynamicChatToken(this.props.userName, {
            TWILIO_ACCOUNT_SID,
            TWILIO_API_KEY,
            TWILIO_API_SECRET,
          });
        } else {
          tokenResponse = await getChatToken(this.props.userName);
        }
      // Initialize the Chat client
      window.Twilio.Chat.Client.create(tokenResponse.token)
        .then((client) => {
          this.chatClient = client;
          this.chatClient
            .getSubscribedChannels()
            .then(() => this._joinChannel());

          // when the access token is about to expire, refresh it
          this.chatClient.on("tokenAboutToExpire", () => {
            // refreshToken(username);
          });

          // if the access token already expired, refresh it
          this.chatClient.on("tokenExpired", () => {
            // refreshToken(username);
          });

          // Alert the user they have been assigned a random username
          const username = tokenResponse.identity;
          this.ownUsername = username;
          console.log("username :>> ", username);
          // print('You have been assigned a random username of: '
          // + '<span class="me">' + username + '</span>', true);
        })
        .catch((error) => {
          console.error(error);
          // print('There was an error creating the chat client:<br/>' + error, true);
          // print('Please check your .env file.', false);
        });
    } catch (error) {}
  };

  _joinChannel = () => {
    this.chatClient
      .getChannelByUniqueName(this.props.roomName)
      .then((channel) => {
        this.generalChannel = channel;
        console.log(`Joined chat channel ${this.props.roomName}`);
        // console.log(this.generalChannel);
        this._setupChannel();
      })
      .catch(() => {
        // If it doesn't exist, let's create it
        console.log(`Creating chat channel ${this.props.roomName}`);
        this.chatClient
          .createChannel({
            uniqueName: this.props.roomName,
            friendlyName: `${this.props.roomName}'s room`,
          })
          .then((channel) => {
            console.log(`Created chat channel ${this.props.roomName}`);
            console.log(channel);
            this.generalChannel = channel;
            this._setupChannel();
          })
          .catch((channel) => {
            console.log("Channel could not be created:");
            console.log(channel);
          });
      });
  };

  _setupChannel = () => {
    console.log("Channel created");
    this._updateChannelStatus(true);
    // Join the general channel
    this.generalChannel.join().then(function (channel) {
      // print('Joined channel as '
      // + '<span class="me">' + username + '</span>.', true);
    });

    // Listen for new messages sent to the channel
    this.generalChannel.on("messageAdded", (message) => {
      // printMessage(message.author, message.body);
      if (!this._checkIfMessageContainsSignalCode(message)) {
        this._appendIncomingMessage(message);
      }
    });
  };

  _checkIfMessageContainsSignalCode = (message) => {
    let containsSignalCode = false;
    const chatCode = chatCodes.find(cc => cc.code === message.body);
    if (chatCode && chatCode.action) {
      switch(chatCode.action) {
        case "chat-reset": {
          this._resetChat();
          containsSignalCode = true;
          break;
        }
        default: {}
      }
    }
    return containsSignalCode;
  }

  _resetChat = () => {
    this.setState({ messages: [] });
  }

  _appendIncomingMessage = (message) => {
    console.log("message.author :>> ", message.author);
    console.log("this.ownUsername :>> ", this.ownUsername);
    if (message.author !== this.ownUsername) {
      // Other's message
      const { messages, unreadCount } = this.state;
      messages.push({
        isMyMessage: false,
        messageText: message.body,
        timestamp: +new Date(),
        author: message.author,
      });
      this.setState({ messages, unreadCount: unreadCount + 1 }, () => {
        this._clearMessageText();
        this._scrollToBottom();
      });
    }
  };

  // To prepare jquery based chat UI
  _initializeChatWindow = () => {
    $(document).delegate(".chat-btn", "click", function () {
      var value = $(this).attr("chat-value");
      var name = $(this).html();
      $("#chat-input").attr("disabled", false);
      // generate_message(name, "self");
    });

    $("#chat-circle").click(() => {
      $("#chat-circle").toggle("scale");
      $(".chat-box").toggle("scale");
      this._scrollToBottom();
    });

    $(".chat-box-toggle").click(function () {
      $("#chat-circle").toggle("scale");
      $(".chat-box").toggle("scale");
    });
  };

  _updateMessageText = (messageBoxText) => {
    this.setState({ messageBoxText });
  };

  _clearMessageText = (messageBoxText) => {
    this.setState({ messageBoxText: "" });
  };

  _sendNewMessage = (e) => {
    e.preventDefault();
    const { messageBoxText, messages } = this.state;
    if (messageBoxText.trim().length) {
      messages.push({
        isMyMessage: true,
        messageText: messageBoxText,
        timestamp: +new Date(),
        author: this.props.userName,
      });
      this.generalChannel.sendMessage(messageBoxText);
      this.setState({ messages }, () => {
        this._clearMessageText();
        this._scrollToBottom();
        this._uploadFiles();
      });
    } else if (this.state.filesSelected.length) {
      this._uploadFiles();
    }
  };

  _sendMessageResetSignal = () => {
    const chatCode = chatCodes.find(cc => cc.action === "chat-reset");
    this.generalChannel.sendMessage(chatCode.code);
  }

  _scrollToBottom = () => {
    $(".chat-logs")
      .stop()
      .animate({ scrollTop: $(".chat-logs")[0].scrollHeight }, 1000);
  };

  _messageContainsFile = (message) => {
    if (message.messageText.indexOf("::FILE_CONTENT::") > -1) {
      return true;
    } else {
      return false;
    }
  };

  _getFileLink = (message) => {
    return message.messageText.split("::FILE_CONTENT::")[1];
  };

  _getFileName = (message) => {
    const link = message.messageText.split("::FILE_CONTENT::")[1];
    return this._formatFileName(link);
  };

  _formatFileName = filePath => {
    const parts = filePath.split("/");
    // Now remove the cuid part
    const dotParts = parts[parts.length - 1].split('.');
    let formattedFileName = "";
    for (let index = 0; index < dotParts.length; index++) {
      if (index === 0) {
        formattedFileName = dotParts[index].split("_").slice(0,-1).join("_");
      } else {
        formattedFileName += `.${dotParts[index]}`
      }
    }
    return formattedFileName;
  }

  _downloadFile = (link) => {
    fetch(link)
      .then((response) => response.blob())
      .then((blob) => {
        const blobURL = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = blobURL;
        a.style = "display: none";
        a.target = "_blank";
        document.body.appendChild(a);
        a.setAttribute("download", this._formatFileName(link));
        a.click();
      })
      .catch(() => {});
  };

  _renderMessage = (message, messageIndex) => {
    const classname = message.isMyMessage ? "chat-msg self" : "chat-msg user";
    const profileImage = message.isMyMessage
      ? "https://cdn.iconscout.com/icon/free/png-512/avatar-372-456324.png"
      : "https://cdn2.iconfinder.com/data/icons/flatfaces-everyday-people-square/128/asian_boy_male_man_face_avatar-512.png";
    return (
      <>
        <div id={"cm-message-" + messageIndex} className={classname}>
          <span className="msg-avatar">
            {/* <img src={profileImage} alt="" /> */}
            <div className="initials">
              {message.author.substring(0, 1).toUpperCase()}
            </div>
          </span>
          <div className="cm-msg-text">
            <div className="author">
              {message.isMyMessage ? `${message.author} (Me)` : message.author}
            </div>
            {this._messageContainsFile(message) ? (
              <div
                className="fileDownloadWrapper"
                onClick={(e) => this._downloadFile(this._getFileLink(message))}
              >
                <div className="icon">
                  <i className="fa fa-download" aria-hidden="true"></i>
                </div>
                {this._getFileName(message)}
              </div>
            ) : (
              <div className="message">{message.messageText}</div>
            )}
          </div>
        </div>
      </>
    );
  };

  _resetUnreadCount = () => {
    this.setState({ unreadCount: 0 });
  };

  _handleFileChange = (e) => {
    if (e && e.target && e.target.files) {
      this.setState(
        { filesSelected: [...this.state.filesSelected, ...e.target.files] },
        () => {
          this.refs.fileInput.value = "";
        }
      );
    } else {
      this.refs.fileInput.value = "";
    }
  };

  _cancelFiles = () => {
    this.setState({ filesSelected: [] }, () => {
      this.refs.fileInput.value = "";
    });
  };

  _uploadFiles = async () => {
    if (this.state.filesSelected.length) {
      let { uploadQueue } = this.state;
      uploadQueue = uploadQueue + this.state.filesSelected.length;
      const files = [...this.state.filesSelected];
      this.setState({ filesSelected: [], uploadQueue }, async () => {
        for (let file of files) {
          const { messageBoxText, messages } = this.state;
          const formData = new FormData();
          formData.append("upload", file, file.name);
          formData.append("roomName", decodeURIComponent(this.props.roomName));
          const { fileLocation } = await uploadMedia(formData);
          let { uploadQueue } = this.state;
          uploadQueue--;
          messages.push({
            isMyMessage: true,
            messageText: `::FILE_CONTENT::${fileLocation}`,
            timestamp: +new Date(),
            author: this.props.userName,
          });
          this.generalChannel.sendMessage(`::FILE_CONTENT::${fileLocation}`);
          this.setState({ messages, uploadQueue }, () => {
            this._clearMessageText();
            this._scrollToBottom();
          });
        }
      });
    }
  };

  render() {
    const {
      messages,
      messageBoxText,
      unreadCount,
      channelConnected,
      uploadQueue,
      filesSelected,
    } = this.state;
    if (channelConnected) {
      return (
        <div className="chatBoxWrapper" onClick={this._resetUnreadCount}>
          <div>
            <div id="chat-circle" className="btn btn-raised">
              <div id="chat-overlay" />
              <i className="material-icons">speaker_phone</i>
              {unreadCount > 0 && <div className="unread">{unreadCount}</div>}
            </div>
            <div className="chat-box">
              <div className="chat-box-header">
                {`Room: ${decodeURIComponent(this.props.roomName)}`}
                <span className="chat-box-toggle">
                  <i className="material-icons">close</i>
                </span>
              </div>
              <div className="chat-box-body">
                <div className="chat-box-overlay"></div>
                <div className="chat-logs">
                  {messages.map((message, messageIndex) => (
                    <React.Fragment key={messageIndex}>
                      {this._renderMessage(message, messageIndex)}
                    </React.Fragment>
                  ))}
                </div>
                {/*chat-log */}
              </div>
              {uploadQueue > 0 ? (
                <div className="uploadProgressDisplay">
                  <div className="loaderWrapper">
                    <Loader
                      type="TailSpin"
                      color="black"
                      height={20}
                      width={20}
                    />
                  </div>
                  Uploading {uploadQueue} file
                  {uploadQueue > 1 ? "s" : ""}
                </div>
              ) : null}
              {filesSelected.length ? (
                <div className="mediaDisplay">
                  <i className="fa fa-paperclip" aria-hidden="true"></i> &nbsp;{" "}
                  {filesSelected.length} file
                  {filesSelected.length > 1 ? "s" : ""} selected
                  <div
                    className="cancelFiles"
                    onClick={(e) => this._cancelFiles()}
                  >
                    +
                  </div>
                </div>
              ) : null}
              <div className="chat-input">
                <form onSubmit={(e) => this._sendNewMessage(e)}>
                  <input
                    type="text"
                    name={+new Date()}
                    id={"chat-input"}
                    placeholder="Send a message..."
                    value={messageBoxText}
                    onChange={(e) => this._updateMessageText(e.target.value)}
                  />
                  <button
                    type="button"
                    className="selectFile"
                    onClick={(e) => {
                      this.refs.fileInput.click();
                    }}
                  >
                    +
                  </button>
                  <button
                    type="button"
                    className="chat-submit"
                    id="chat-submit"
                    onClick={(e) => this._sendNewMessage(e)}
                  >
                    <i className="material-icons">send</i>
                  </button>
                </form>
              </div>
            </div>
          </div>
          <input
            ref="fileInput"
            type="file"
            multiple
            style={{ width: 0, height: 0, overflow: "hidden" }}
            onChange={(e) => this._handleFileChange(e)}
          />
        </div>
      );
    } else {
      return null;
    }
  }
}

export default ChatBox;
