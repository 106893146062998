import React, { Component } from "react";
import { Button, Container, Link } from "react-floating-action-button";
import {
  getVideoCallToken,
  getDynamicVideoCallToken,
  getTwilioCreds,
} from "../../http-calls/index";
import "./web-call.scss";
import Video from "twilio-video";
import {
  extractQueryParams,
  getMapSize,
  sleepTime,
  showToast,
} from "../../helper-methods/index";
import { showLoader, hideLoader } from "../../redux/actions/loader-data";
import { connect } from "react-redux";
import { CopyToClipboard } from "react-copy-to-clipboard";
import WaitingScreen from "../../components/waiting-screen/waiting-screen";
import { Resizable, ResizableBox } from "react-resizable";
import ChatBox from "../../components/chat-box/chat-box";
import CallPrompt from "../../components/call-prompt/call-prompt";
import Loader from "react-loader-spinner";
import SessionManager from "../../components/session-manager/session-manager";

class WebCall extends Component {
  state = {
    videoToken: null,
    identity: null,
    roomName: null,
    roomNameErr: false, // Track error for room name TextField. This will    enable us to show an error message when this variable is true
    previewTracks: null,
    localMediaAvailable: false, // Represents the availability of a LocalAudioTrack(microphone) and a LocalVideoTrack(camera)
    hasJoinedRoom: false,
    activeRoom: null, // Track the current active room,
    isFullScreenEnabled: false,
    isVideoEnabled: true,
    isAudioEnabled: true,
    room: null,
    localParticipant: null,
    remoteParticipants: [],
    hideLocal: false,
    videoMode: "oneToOne", // All options: ['oneToOne', 'oneToMany']
    nextTrackIndex: 0,
    webViews: [],
    isScreenShareEnabled: false,
    screenShareTracks: [],
    islargeScreenVisible: false,
    roomLink: "",
    isScreenShareModeActive: false,
    disableMedia: true,
    isCustomTwilioCredsAvailable: false,
    isCallReady: false,
    callPrompt: {
      isVisible: false,
      mode: "input",
      errorText: "",
      loaderText: "",
    },
    authenCode: null,
    isSessionStarted: false,
    isHost: false,
    isCallInitialized: false, //  Indicates if connected to twilio call and chat server
    mediaUrl: null,
    logoUrl: null,
  };
  tracks = {};

  chatBox = React.createRef();

  async componentDidMount() {
    try {
      // this.props.showLoader("Please wait");
      this._showPromptLoader("Please wait");
      await this._extractPrimaryParams();
      await this._fetchTwilioConfigs();
      this._registerEvents();
      this._prepareLink();
      this._showUserPromptIfRequired();
    } catch (error) {
      console.log("error :>> ", error);
    }
  }

  _updateCallPromptState = (callPrompt) => {
    this.setState({ callPrompt });
  };

  _onUserNameSubmit = (username) => {
    this.setState(
      {
        identity: username,
      },
      () => {
        this._onUserNameSet();
      }
    );
  };

  _prepareLink = () => {
    let roomLink =
      window.location.origin +
      `/video-call?room=${this.state.roomName}&authencode=${this.state.authenCode}`;
    this.setState({ roomLink });
  };

  _shareLink = () => {
    showToast("Room link copied to clipboard!", "success");
  };

  _showScreenShareTrack = (track, participant) => {
    // Add additional webview
    const { screenShareTracks, webViews, videoMode, room } = this.state;
    screenShareTracks.push({
      participant,
      participantSid: participant.sid,
      trackId: track._id,
    });
    webViews.push({
      participant,
      isRemote: true,
      participantSid: participant.sid,
      trackId: track._id,
      track,
      isScreenShare: true,
      refName: "screenShareWindow_" + participant.sid,
    });
    if (videoMode === "oneToMany") {
      this.setState({ webViews, screenShareTracks }, () => {
        const remoteViewContainer = this.refs[
          "screenShareWindow_" + participant.sid
        ];
        this.attachTracks([track], remoteViewContainer);
        this._toggleScreenShareMode(true);
      });
    } else {
      // One to one, switch to one to many mode
      this.setState(
        { webViews, screenShareTracks, videoMode: "oneToMany" },
        () => {
          const localViewContainer = this.refs[webViews[0].refName];
          if (
            localViewContainer &&
            !localViewContainer.querySelector("video")
          ) {
            this.attachParticipantTracks(
              room.localParticipant,
              localViewContainer
            );
          }
          // Attach remote participants
          let index = 0;
          room.participants.forEach((participant) => {
            if (index === 0) {
              index++;
              // Attach video tracks
              if (this.tracks[participant.sid]) {
                this.tracks[participant.sid].video.forEach((v, vIndex) => {
                  if (vIndex === 0) {
                    const remoteViewContainer = this.refs[
                      "remoteWindow_" + participant.sid
                    ];
                    this.attachTracks([v], remoteViewContainer);
                  }
                });
                this.tracks[participant.sid].audio.forEach((a) => {
                  const remoteViewContainer = this.refs[
                    "remoteWindow_" + participant.sid
                  ];
                  this.attachTracks([a], remoteViewContainer);
                });
              }
            }
          });
          // Attach screen share video
          const remoteViewContainer = this.refs[
            "screenShareWindow_" + participant.sid
          ];
          this.attachTracks([track], remoteViewContainer);
          this._toggleScreenShareMode(true);
        }
      );
    }
  };

  _onUserNameSet = async () => {
    try {
      this._showPromptLoader("Joining you in");
      // await this._fetchTwilioConfigs();
      // // Fetch video token
      await this._fetchVideoToken();
      this._hidePromptLoader();
      this._extractAdditionalParams();
      // // Initialize twilio video library
      await this._initializeVideoChat();
    } catch (error) {
      console.log("error :>> ", error);
    }
  };

  _showUserPromptIfRequired = () => {
    const { identity } = this.state;
    if (identity && identity.length) {
      setTimeout(() => {
        this._onUserNameSet();
      }, 10000);
    } else {
      this.setState({
        callPrompt: {
          isVisible: true,
          mode: "input",
        },
      });
    }
  };

  shareScreen = async () => {
    try {
      const { videoRoom, localVideoTrack, screenTrack } = this.state;

      if (!screenTrack) {
        const stream = await navigator.mediaDevices.getDisplayMedia({
          video: true,
        });
        const newScreenTrack = stream.getVideoTracks()[0];

        newScreenTrack.onended = () => {
          console.log("Ended");
          var tracks = Array.from(room.localParticipant.tracks.values());
          this.state.localParticipant.unpublishTrack(tracks[2]);
          this.setState({ isScreenShareEnabled: false }, () => {});
        };

        this.setState({
          screenTrack: new Video.LocalVideoTrack(newScreenTrack),
        });

        const { isVideoEnabled, room } = this.state;
        var tracks = Array.from(room.localParticipant.tracks.values());
        // tracks[1].detach()
        this.state.localParticipant.publishTrack(newScreenTrack);
        // this.state.localParticipant.unpublishTrack(tracks[1]);
        this.setState({ isScreenShareEnabled: true }, () => {});
      } else {
        // videoRoom.localParticipant.unpublishTrack(screenTrack);
        // videoRoom.localParticipant.publishTrack(localVideoTrack);
        // this.stopScreenTrack();
      }
    } catch (error) {
      console.log("error :>> ", error);
      // this.stopScreenTrack();

      this.setState({
        errorMessage: error.message,
      });
    }
  };

  _initializeVideoChat = () => {
    console.log("_initializeVideoChat");
    return new Promise((resolve, reject) => {
      this.joinRoom();
      resolve();
    });
  };

  _onTwilioCredFetch = () => {};

  _fetchTwilioConfigs = () => {
    return new Promise(async (resolve, reject) => {
      const { authenCode } = this.state;
      try {
        const twilioCreds = JSON.parse(await getTwilioCreds(authenCode));
        if (
          twilioCreds.TwilioSID &&
          twilioCreds.TwilioSID.length &&
          twilioCreds.TwilioApiKey &&
          twilioCreds.TwilioApiKey.length &&
          twilioCreds.TwilioSecret &&
          twilioCreds.TwilioSecret.length
        ) {
          this.setState(
            {
              TWILIO_ACCOUNT_SID: twilioCreds.TwilioSID,
              TWILIO_API_KEY: twilioCreds.TwilioApiKey,
              TWILIO_API_SECRET: twilioCreds.TwilioSecret,
              isCustomTwilioCredsAvailable: true,
              mediaUrl:
                twilioCreds.Media && twilioCreds.Media.length
                  ? twilioCreds.Media
                  : null,
              logoUrl:
                twilioCreds.Logo && twilioCreds.Logo.length
                  ? twilioCreds.Logo
                  : null,
            },
            () => {
              resolve();
            }
          );
        } else {
          // Proper twilio data is not available
          this._updateCallPromptState({
            isVisible: true,
            mode: "alert",
            errorText: "Access denied",
          });
          reject();
        }
      } catch (error) {
        this._updateCallPromptState({
          isVisible: true,
          mode: "alert",
          errorText: "Access denied",
        });
        reject();
      }
    });
  };

  _extractPrimaryParams = () => {
    return new Promise(async (resolve, reject) => {
      const params = extractQueryParams();
      console.log("params :>> ", params);
      if (
        params.authencode &&
        params.authencode.length &&
        params.room &&
        params.room.length
      ) {
        const stateParams = {
          authenCode: params.authencode,
          roomName: params.room,
        };
        if (params.user && params.user.length) {
          stateParams.identity = params.user;
        }
        this.setState(stateParams, () => {
          resolve();
        });
      } else {
        this._updateCallPromptState({
          isVisible: true,
          mode: "alert",
          errorText: "Access denied",
        });
        reject();
      }
    });
  };

  _extractAdditionalParams = () => {
    const params = extractQueryParams();
    if (params.hasOwnProperty("noMedia") || params.hasOwnProperty("nomedia")) {
      this.setState({ disableMedia: true });
    } else {
      this.setState({ disableMedia: false });
    }
    if (params.hasOwnProperty("redirectUrl")) {
      this.setState({ redirectUrl: params.redirectUrl });
    }
    if (
      params.hasOwnProperty("host") &&
      params.hasOwnProperty("user") &&
      params.user.length
    ) {
      this.setState({ isHost: true });
    }
  };

  _showPromptLoader = (loaderText) => {
    this.setState({
      callPrompt: {
        isVisible: true,
        mode: "loader",
        loaderText: loaderText,
      },
    });
  };

  _hidePromptLoader = () => {
    this.setState({
      callPrompt: {
        isVisible: false,
        mode: "loader",
        loaderText: "",
      },
    });
  };

  _showPromptError = (errorText) => {
    this.setState({
      callPrompt: {
        isVisible: true,
        mode: "alert",
        errorText: errorText,
      },
    });
  };

  _hidePromptLoader = () => {
    this.setState({
      callPrompt: {
        isVisible: false,
        mode: "alert",
        errorText: "",
      },
    });
  };

  _fetchVideoToken = () => {
    return new Promise(async (resolve, reject) => {
      const {
        identity,
        roomName,
        isCustomTwilioCredsAvailable,
        TWILIO_ACCOUNT_SID,
        TWILIO_API_KEY,
        TWILIO_API_SECRET,
      } = this.state;
      try {
        if (isCustomTwilioCredsAvailable) {
          const { token } = await getDynamicVideoCallToken(identity, {
            TWILIO_ACCOUNT_SID,
            TWILIO_API_KEY,
            TWILIO_API_SECRET,
          });
          this.setState({ videoToken: token }, () => {
            resolve();
          });
        } else {
          const token = await getVideoCallToken(identity, roomName);
          this.setState({ videoToken: token }, () => {
            resolve();
          });
        }
      } catch (error) {
        console.log("error :>> ", error);
        this._showPromptError("Access denied");
        reject();
      }
    });
  };

  // V3
  joinRoom = () => {
    // Show an error message on room name text field if user triesto join a room without providing a room name. This is enabled by setting `roomNameErr` to true
    if (!this.state.roomName.trim()) {
      this.setState({ roomNameErr: true });
      return;
    }

    console.log("Joining room '" + this.state.roomName + "'...");
    let connectOptions = {
      name: this.state.roomName,
    };

    if (this.state.previewTracks) {
      connectOptions.tracks = this.state.previewTracks;
    }

    // Connect to a room by providing the token and connection    options that include the room name and tracks. We also show an alert if an error occurs while connecting to the room.
    Video.connect(this.state.videoToken, connectOptions).then(
      this.roomJoined,
      (error) => {
        alert("Could not connect to Twilio: " + error.message);
      }
    );
  };

  // V3
  // Attach the Tracks to the DOM.
  attachTracks = (tracks, container) => {
    console.log("tracks :>> ", tracks);
    tracks.forEach((track) => {
      console.log("track.attach :>> ", track);
      container.appendChild(track.attach());
    });
  };

  // Attach the Participant's Tracks to the DOM.
  attachParticipantTracks = (participant, container) => {
    var tracks = Array.from(participant.tracks.values());
    this.attachTracks(tracks, container);
  };

  // V3
  _attachParticipantEvents = (participant) => {
    participant.on("trackEnabled", (track) => {
      // show the track again
      console.log("participant :>> ", participant);
    });
    participant.on("trackDisabled", (track) => {
      // show the track again
      console.log("participant :>> ", participant);
    });
  };

  // V3
  _showOneToOneView = (room) => {
    let { activeRoom, videoMode } = this.state;
    this.setState({ videoMode: "oneToOne" }, () => {
      const localViewContainer = this.refs.floatingLocalVideo;
      if (!localViewContainer.querySelector("video")) {
        this.setState({ localParticipant: room.localParticipant }, () => {
          this.attachParticipantTracks(
            room.localParticipant,
            localViewContainer
          );
        });
      }
      // Attach remote participant if available
      if (room.participants.length === 1) {
        this.setState({ remoteParticipants: room.participants }, () => {
          room.participants.forEach((participant) => {
            var previewContainer = this.refs.fullscreenRemoteVideo;
            this.attachParticipantTracks(participant, previewContainer);
            this._attachParticipantEvents(participant);
          });
        });
      }
    });
  };

  roomJoined = (room) => {
    this.setState({ isCallInitialized: true });
    this.props.hideLoader();
    const webViews = [
      {
        participant: room.localParticipant,
        participantSid: room.localParticipant.sid,
        isRemote: false,
        refName: "remoteWindow_" + room.localParticipant.sid,
      },
    ];
    this.setState({ room, webViews }, () => {
      // Called when a participant joins a room
      // Initially
      console.log("Joined as '" + this.state.identity + "'");
      this.setState({
        activeRoom: room,
        localMediaAvailable: true,
        hasJoinedRoom: true, // Removes ‘Join Room’ button and shows ‘Leave Room’
      });

      // User joined a room
      if (room.participants.size > 1) {
        // More than 2 participants are there
        room.participants.forEach((participant) => {
          this._addRemoteParticipant(participant);
        });
        this._switchToOneToManyMode();
      } else {
        room.participants.forEach((participant) => {
          this._addRemoteParticipant(participant);
        });
        console.log("room.participants :>> ", room.participants);
        // Currently 2 participants are in the room
        console.log(234);
        this._switchToOneToOneMode();
      }

      // Attach LocalParticipant's tracks to the DOM, if not already attached.
      // console.log('Total participants :>> ',room.participants);
      // var previewContainer = this.refs.localMedia1;
      // if (!previewContainer.querySelector("video")) {
      //   this.setState({ localParticipant: room.localParticipant }, () => {
      //     this.attachParticipantTracks(room.localParticipant, previewContainer);
      //     this.attachParticipantTracks(room.localParticipant, this.refs.localMedia);
      //   });
      // }
      // ... more event listeners
      // Attach the Tracks of the room's participants.
      // this.setState({ remoteParticipants: room.participants }, () => {
      //   room.participants.forEach((participant) => {
      //     console.log("Already in Room: '" + participant.identity + "'");
      //     var previewContainer = this.refs.remoteMedia;
      //     this.attachParticipantTracks(participant, previewContainer);
      //     this._attachParticipantEvents(participant)
      //   });
      // });

      // Participant joining room
      room.on("participantConnected", (participant) => {
        console.log("Joining: '" + participant.identity + "'");
        console.log(
          "Total participants :>> ",
          room.localParticipant + room.participants
        );
        this._addRemoteParticipant(participant);
      });

      // Attach participant’s tracks to DOM when they add a track
      room.on("trackAdded", (track, participant) => {
        console.log(participant.identity + " added track: " + track.kind);
        console.log("track :>> ", track);
        if (!this.tracks[participant.sid]) {
          this.tracks[participant.sid] = {};
        }
        if (!this.tracks[participant.sid][track.kind]) {
          this.tracks[participant.sid][track.kind] = [];
        }
        this.tracks[participant.sid][track.kind].push(track);
        const tracksLength = this.tracks[participant.sid][track.kind].length;
        // var previewContainer = this.refs.remoteMedia;
        // this.attachTracks([track], previewContainer);
        console.log("tracksLength :>> ", tracksLength);
        if (tracksLength > 1) {
          this._showScreenShareTrack(track, participant);
        } else {
          this._addRemoteParticipantTrack(track, participant);
        }
        // this._addRemoteParticipantTrack(track, participant);
      });

      // Detach participant’s track from DOM when they remove a track.
      room.on("trackRemoved", (track, participant) => {
        console.log("trackRemoved :>> ", participant);
        const trackIndex = this.tracks[participant.sid][track.kind].find(
          (t) => t._id === track._id
        );
        // this.detachTracks([track]);
        // this.tracks[participant.sid][track.kind].splice(trackIndex, 1);
        this._onRemoteTrackDeattach(track, participant);
      });

      // Detach all participant’s track when they leave a room.
      room.on("participantDisconnected", (participant) => {
        console.log("Participant '" + participant.identity + "' left the room");
        // this.detachParticipantTracks(participant);
        console.log(
          "Total participants :>> ",
          room.localParticipant + room.participants
        );
        this._onRemoteParticipantExit(participant);
      });

      // Once the local participant leaves the room, detach the Tracks
      // of all other participants, including that of the LocalParticipant.
      room.on("disconnected", () => {
        if (this.state.previewTracks) {
          this.state.previewTracks.forEach((track) => {
            track.stop();
          });
        }
        this.detachParticipantTracks(room.localParticipant);
        room.participants.forEach(this.detachParticipantTracks);
        this.state.activeRoom = null;
        this.setState({ hasJoinedRoom: false, localMediaAvailable: false });
      });
    });
  };

  detachTracks = (tracks) => {
    tracks.forEach((track) => {
      track.detach();
    });
  };

  detachParticipantTracks = (participant) => {
    var tracks = Array.from(participant.tracks.values());
    this.detachTracks(tracks);
  };

  switchToFullScreen = () => {
    const elem = document.getElementById("web-call-wrapper");
    this.setState({ isFullScreenEnabled: true });
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) {
      /* Firefox */
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) {
      /* Chrome, Safari and Opera */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      /* IE/Edge */
      elem.msRequestFullscreen();
    }
  };

  _registerEvents = () => {
    const docRef = window.document;
    if (docRef.addEventListener) {
      docRef.addEventListener("fullscreenchange", this.onFullScreenExit, false);
      docRef.addEventListener(
        "mozfullscreenchange",
        this.onFullScreenExit,
        false
      );
      docRef.addEventListener(
        "MSFullscreenChange",
        this.onFullScreenExit,
        false
      );
      docRef.addEventListener(
        "webkitfullscreenchange",
        this.onFullScreenExit,
        false
      );
    }
  };

  onFullScreenExit = (e) => {
    if (
      !document.fullscreenElement &&
      !document.webkitIsFullScreen &&
      !document.mozFullScreen &&
      !document.msFullscreenElement
    ) {
      ///fire your event
      this.setState({ isFullScreenEnabled: false });
    }
  };

  toggleFullScreenMode = () => {
    const { isFullScreenEnabled } = this.state;
    if (isFullScreenEnabled) {
      // Then disable it
      window.document.exitFullscreen();
      this.onFullScreenExit();
    } else {
      // Then enable it it
      this.switchToFullScreen();
    }
  };

  handleRemoteParticipants = (newParticipant) => {
    // This function will handle other remote participants
    if (newParticipant && newParticipant.isLive && newParticipant.hasVideo) {
      // Enable tracks
      this.setState({
        remoteTracks: this.state.remoteTracks.push(newParticipant),
      });
    }
  };

  toggleLocalVideo = () => {
    // const { isVideoEnabled, previewTracks } = this.state;
    // if (isVideoEnabled) {
    //   const track = [...previewTracks][0].track;
    //   track.stop();
    //   var previewContainer = this.refs.localMedia;
    //   track.detach(previewContainer.current);
    //   // participant.unpublishTrack(track);
    //   this.setState({ isVideoEnabled: false });
    // } else {
    //   var previewContainer = this.refs.localMedia;
    //   if (!previewContainer.querySelector("video")) {
    //     // this.attachParticipantTracks(room.localParticipant, previewContainer);
    //   }
    //   this.setState({ isVideoEnabled: true });
    // }
  };

  toggleAudio = () => {
    const { isAudioEnabled } = this.state;
    this.setState({ isAudioEnabled: !isAudioEnabled }, () => {
      const { isAudioEnabled, room } = this.state;
      var tracks = Array.from(room.localParticipant.tracks.values());
      if (!isAudioEnabled) {
        tracks[0].disable();
      } else {
        tracks[0].enable();
      }
    });
  };

  toggleVideo = () => {
    const { isVideoEnabled } = this.state;
    this.setState({ isVideoEnabled: !isVideoEnabled }, () => {
      const { isVideoEnabled, room } = this.state;
      var tracks = Array.from(room.localParticipant.tracks.values());
      if (!isVideoEnabled) {
        tracks[1].disable();
      } else {
        tracks[1].enable();
      }
    });
  };

  _switchToOneToOneMode = () => {
    this.setState({ videoMode: "oneToOne" }, () => {
      const { room, webViews } = this.state;
      this._attachLocalParticipant();
      const remoteViewContainer = this.refs.fullscreenRemoteVideo;
      if (webViews[1]) {
        const remoteParticipant = webViews[1].participant;
        if (remoteViewContainer) {
          if (
            this.tracks[remoteParticipant.sid] &&
            this.tracks[remoteParticipant.sid].video
          ) {
            this.attachTracks(
              [this.tracks[remoteParticipant.sid].video[0]],
              remoteViewContainer
            );
          }
          if (
            this.tracks[remoteParticipant.sid] &&
            this.tracks[remoteParticipant.sid].audio
          ) {
            this.attachTracks(
              [this.tracks[remoteParticipant.sid].audio[0]],
              remoteViewContainer
            );
          }
        }
      }
    });
  };

  _toggleScreenShareMode = (mode) => {
    this.setState({ isScreenShareModeActive: mode });
  };

  _switchToOneToManyMode = (addTrack = () => {}) => {
    let { room, webViews, remoteParticipants, localParticipant } = this.state;
    this.setState(
      { videoMode: "oneToMany", remoteParticipants: room.participants },
      () => {
        // Add local participant
        this._attachLocalParticipant();
        addTrack();
        // Add remote participants
        // room.participants.forEach((participant) => {
        //   this._addRemoteParticipant(participant);
        // })
        // Attach previous tracks
        let index = 0;
        room.participants.forEach((participant) => {
          if (index === 0) {
            index++;
            // Attach video tracks
            if (this.tracks[participant.sid]) {
              this.tracks[participant.sid].video.forEach((v, vIndex) => {
                if (vIndex === 0) {
                  this._addRemoteParticipantTrack(v, participant);
                }
              });
              this.tracks[participant.sid].audio.forEach((a) => {
                this._addRemoteParticipantTrack(a, participant);
              });
            }
          }
        });
      }
    );
  };

  // v1
  _addRemoteParticipant = (participant) => {
    return new Promise((resolve, reject) => {
      let { remoteParticipants, webViews, nextTrackIndex } = this.state;
      webViews.push({
        participant,
        isRemote: true,
        participantSid: participant.sid,
        trackIndex: nextTrackIndex,
        refName: "remoteWindow_" + participant.sid,
      });
      this.setState(
        { remoteParticipants, nextTrackIndex: nextTrackIndex + 1, webViews },
        () => {
          resolve();
        }
      );
    });
  };

  _showTrackInFullMode = (webView) => {
    this.setState({ islargeScreenVisible: true }, () => {
      // Attach to large screen
      setTimeout(() => {
        const remoteViewContainer = this.refs.largeScreen;
        this.attachTracks([webView.track], remoteViewContainer);
      }, 1000);
    });
  };

  _addRemoteParticipantTrack = (track, participant) => {
    console.log("participant.identity 1:>> ", participant.identity);
    return new Promise((resolve, reject) => {
      const {
        remoteTracks,
        remoteParticipants,
        webViews,
        videoMode,
        room,
      } = this.state;
      if (room.participants.size === 1) {
        console.log("participant.identity 2:>> ", participant.identity);
        if (videoMode === "oneToOne") {
          // No change needed, just add it
          const remoteViewContainer = this.refs.fullscreenRemoteVideo;
          if (remoteViewContainer) {
            this.attachTracks([track], remoteViewContainer);
          }
        } else {
          // Change mode and then add
          this._switchToOneToOneMode();
          // Add track to participant
        }
      } else if (room.participants.size > 1) {
        if (videoMode === "oneToMany") {
          console.log("participant.identity 1:>> ", participant.identity);
          // No change needed, just add it
          const remoteViewContainer = this.refs[
            "remoteWindow_" + participant.sid
          ];
          console.log("remoteViewContainer :>> ", remoteViewContainer);
          console.log("participant.sid :>> ", participant.sid);
          console.log("webViews :>> ", webViews);
          this.attachTracks([track], remoteViewContainer);
        } else {
          console.log("participant.identity 1:>> ", participant.identity);
          // Change mode and then add
          this._switchToOneToManyMode(() => {
            const remoteViewContainer = this.refs[
              "remoteWindow_" + participant.sid
            ];
            console.log("remoteViewContainer :>> ", remoteViewContainer);
            console.log("participant.sid :>> ", participant.sid);
            console.log("webViews :>> ", webViews);
            this.attachTracks([track], remoteViewContainer);
          });
          // Add track to participant
        }
      }
    });
  };

  _onRemoteParticipantExit = (participant) => {
    return new Promise((resolve, reject) => {
      let { remoteParticipants, room, webViews } = this.state;
      // Get the index
      const webViewIndex = webViews.findIndex(
        (webView) => webView.participantSid === participant.sid
      );
      // Remove the index
      console.log("on disconnect: :>> ", webViewIndex);
      webViews.splice(webViewIndex, 1);
      // webViews[webViewIndex].isDisconnected = true;
      this.setState({ remoteParticipants, webViews }, () => {
        if (room.participants.size === 1) {
          this._switchToOneToOneMode();
        }
        resolve();
      });
    });
  };

  // **********  To be removed  ***********
  _onRemoteTrackAttach = (track, participant) => {
    // Identify participants webview
    const {
      webViews,
      remoteTracks,
      remoteParticipants,
      videoMode,
      room,
    } = this.state;
    // remoteTracks[track[0]] = track;
    console.log("room.participants.size :>> ", room.participants.size);
    if (room.participants.size === 1) {
      // const remoteViewContainer = this.refs.fullscreenRemoteVideo;
      if (videoMode === "oneToOne") {
        // No change needed, just add it
        const remoteViewContainer = this.refs.fullscreenRemoteVideo;
        this._addRemoteParticipantTrack([track], participant);
      } else {
        // Change mode and then add
        this._switchToOneToManyMode();
        // Add track to participant
      }
    } else if (room.participants.size > 1) {
      if (videoMode === "oneToMany") {
        // No change needed, just add it
      } else {
        // Change mode and then add
        this._switchToOneToOneMode();
        // Add track to participant
      }
    }
  };

  _onRemoteTrackDeattach = (track, participant) => {
    const { screenShareTracks, webViews, room, videoMode } = this.state;
    const isScreenshareTrack = screenShareTracks.findIndex(
      (t) => t.trackId === track._id
    );
    if (isScreenshareTrack > -1) {
      // Remove webview
      const webViewIndex = webViews.findIndex(
        (wv) => wv.isScreenShare && wv.trackId === track._id
      );
      webViews.splice(webViewIndex, 1);
      screenShareTracks.splice(isScreenshareTrack, 1);
      this.setState(
        { webViews, screenShareTracks, islargeScreenVisible: false },
        () => {
          this._toggleScreenShareMode(false);
          if (room.participants.size === 1) {
            this._switchToOneToOneMode();
          }
        }
      );
    } else {
      if (videoMode === "oneToOne") {
        this.detachParticipantTracks(participant);
        const remoteViewContainer = this.refs.fullscreenRemoteVideo;
        remoteViewContainer.innerHTML = "";
      }
    }
  };

  _toggleScreenShare = () => {
    const { isScreenShareEnabled } = this.state;
    if (isScreenShareEnabled) {
    } else {
      // Enable screen share
      this.shareScreen();
    }
  };

  _attachLocalParticipant = () => {
    const { room, videoMode, webViews } = this.state;
    switch (videoMode) {
      case "oneToOne": {
        const localViewContainer = this.refs.floatingLocalVideo;
        if (localViewContainer && !localViewContainer.querySelector("video")) {
          this.setState({ localParticipant: room.localParticipant }, () => {
            this.attachParticipantTracks(
              room.localParticipant,
              localViewContainer
            );
          });
        }
        break;
      }
      case "oneToMany": {
        const localViewContainer = this.refs[webViews[0].refName];
        if (localViewContainer && !localViewContainer.querySelector("video")) {
          this.setState({ localParticipant: room.localParticipant }, () => {
            this.attachParticipantTracks(
              room.localParticipant,
              localViewContainer
            );
          });
        }
        break;
      }
      default: {
      }
    }
  };

  // _attachRemoteParticipant = participant => {
  //   const { room, videoMode } = this.state;
  //   switch(videoMode) {
  //     case 'oneToOne': {
  //       const remoteViewContainer = this.refs.fullscreenRemoteVideo;
  //       if (remoteViewContainer && !remoteViewContainer.querySelector("video")) {

  //         this.attachParticipantTracks(participant, remoteViewContainer);
  //       }
  //       break;
  //     }
  //     case 'oneToMany': {
  //       break;
  //     }
  //     default: {

  //     }
  //   }
  // }

  _endCall = async () => {
    const { room, redirectUrl } = this.state;
    this.props.showLoader("Disconnecting call");
    const { isHost } = this.state;
    if (isHost) {
      this.chatBox.current._sendMessageResetSignal();
    }
    await sleepTime(1000);
    this.detachParticipantTracks(room.localParticipant);
    await sleepTime(1000);
    if (redirectUrl && redirectUrl.length) {
      window.location = `${window.location.origin}/call-complete?redirectUrl=${redirectUrl}`;
    } else {
      window.location = `${window.location.origin}/call-complete`;
    }
  };

  _renderVideoScreens = () => {
    var currentWindowWidth = window.innerWidth;
    var currentWindowHeight = window.innerHeight;
    const { videoMode, webViews, isScreenShareModeActive } = this.state;
    switch (videoMode) {
      case "oneToOne": {
        return (
          <>
            <ResizableBox
              width={currentWindowWidth * (3 / 4)}
              height={currentWindowHeight * (3 / 4)}
              resizeHandles={["sw", "se", "nw", "ne", "w", "e", "n", "s"]}
              lockAspectRatio={true}
              minConstraints={[
                currentWindowWidth / 15,
                currentWindowHeight / 15,
              ]}
              maxConstraints={[currentWindowWidth, currentWindowHeight]}
            >
              <div id="fullscreenRemoteVideo" ref="fullscreenRemoteVideo"></div>
            </ResizableBox>
            <div id="floatingLocalVideo" ref="floatingLocalVideo"></div>
          </>
        );
        break;
      }
      case "oneToMany": {
        return (
          <div
            className={
              "gridView " + (isScreenShareModeActive ? "screenShareView " : "")
            }
          >
            {webViews.map((webView, webViewIndex) => (
              <div
                className={
                  "videoWindow " +
                  (isScreenShareModeActive
                    ? webView.isScreenShare
                      ? " screenShareFull"
                      : " screenShareRight"
                    : "")
                }
                key={webView.participant.identity}
                ref={webView.refName}
                onClick={() => {
                  if (webView.isScreenShare) {
                    this._showTrackInFullMode(webView);
                  }
                }}
              >
                <div className="userInfo">
                  {webView.participant.identity}
                  {!webView.isRemote && "(You)"}
                  {webView.isScreenShare && " sharing screen"}
                </div>
              </div>
            ))}
            {/* <div className="videoWindow">
              <div className="userInfo">
                John
              </div>
            </div>
            <div className="videoWindow">
              <div className="userInfo">
                John
              </div>
            </div><div className="videoWindow">
              <div className="userInfo">
                John
              </div>
            </div> */}
          </div>
        );
        break;
      }
      default: {
      }
    }
  };

  _toggleMedia = () => {
    this.setState({ disableMedia: !this.state.disableMedia });
  };

  _onSessionStart = () => {
    this.setState({
      isSessionStarted: true,
    });
  };

  render() {
    const {
      isFullScreenEnabled,
      isVideoEnabled,
      isAudioEnabled,
      hideLocal,
      videoMode,
      isScreenShareEnabled,
      islargeScreenVisible,
      identity,
      roomName,
      room,
      isCustomTwilioCredsAvailable,
      TWILIO_ACCOUNT_SID,
      TWILIO_API_KEY,
      TWILIO_API_SECRET,
      disableMedia,
      callPrompt,
      isSessionStarted,
      isHost,
      authenCode,
      isCallInitialized,
      webViews,
    } = this.state;
    return (
      <>
        <div id="web-call-wrapper">
          <div className="logoWrapper">
            <div className="logoInnerWrapper">
              <img
                src={
                  this.state.logoUrl
                    ? this.state.logoUrl
                    : require("../../assets/Lets_Connect_with_text_white.png")
                }
                alt="alt"
                className="logoImg"
              />
            </div>
          </div>
          <SessionManager
            isSessionStarted={isSessionStarted}
            isCallInitialized={isCallInitialized}
            isHost={isHost}
            authenCode={authenCode}
            remoteParticipants={webViews}
            onSessionStart={this._onSessionStart}
            roomLink={this.state.roomLink}
            roomName={roomName}
          />
          {islargeScreenVisible ? (
            <div id="largeScreenWapper">
              <div id="largeScreen" ref="largeScreen">
                <div id="bottomBar">
                  <div
                    className="close"
                    onClick={(e) => {
                      this.setState({ islargeScreenVisible: false });
                    }}
                  >
                    Exit
                  </div>
                </div>
              </div>
            </div>
          ) : null}
          {videoMode === "oneToOne" ? (
            <>
              {!room ||
              !room.participants ||
              (room && room.participants && room.participants.size === 0) ? (
                <WaitingScreen
                  disableMedia={disableMedia}
                  logoUrl={this.state.logoUrl}
                  mediaUrl={this.state.mediaUrl}
                />
              ) : (
                <></>
              )}
            </>
          ) : null}
          {identity && roomName ? (
            <ChatBox
              ref={this.chatBox}
              userName={identity}
              roomName={roomName}
              TWILIO_ACCOUNT_SID={TWILIO_ACCOUNT_SID}
              isCustomTwilioCredsAvailable={isCustomTwilioCredsAvailable}
              TWILIO_API_KEY={TWILIO_API_KEY}
              TWILIO_API_SECRET={TWILIO_API_SECRET}
            />
          ) : null}
          {this._renderVideoScreens()}
          <div id="actions-sheet-wrapper">
            <div id="actions-sheet">
              <div
                className={isAudioEnabled ? "action-btn enabled" : "action-btn"}
                onClick={this.toggleAudio}
              >
                <i className="fa fa-microphone" aria-hidden="true" />
              </div>
              <div
                className={isVideoEnabled ? "action-btn enabled" : "action-btn"}
                onClick={this.toggleVideo}
              >
                <i className="fa fa-eye" aria-hidden="true" />
              </div>
              <div
                className={!disableMedia ? "action-btn enabled" : "action-btn"}
                onClick={this._toggleMedia}
              >
                <i className="fa fa-music" aria-hidden="true"></i>
              </div>
              <div className="action-btn red" onClick={this._endCall}>
                <i className="fa fa-phone" aria-hidden="true" />
              </div>
              <div className="action-btn" onClick={(e) => this._shareLink()}>
                <CopyToClipboard text={this.state.roomLink}>
                  <i className="fa fa-share-alt" aria-hidden="true"></i>
                </CopyToClipboard>
              </div>
              <div
                className={
                  isFullScreenEnabled ? "action-btn enabled" : "action-btn"
                }
                onClick={(e) => this.toggleFullScreenMode()}
              >
                <i className="fa fa-arrows-alt" aria-hidden="true" />
              </div>
              <div
                onClick={this._toggleScreenShare}
                className={
                  isScreenShareEnabled ? "action-btn enabled" : "action-btn"
                }
              >
                <i className="fa fa-desktop" aria-hidden="true" />
              </div>
            </div>
          </div>
          <Container>
            <Link
              href="#"
              tooltip="Create note link"
              icon="far fa-sticky-note"
            />
            <Link href="#" tooltip="Add user link" icon="fa fa-plus" />
            <Button
              tooltip="Options"
              icon="fa fa-list"
              rotate={false}
              onClick={() => alert("FAB Rocks!")}
            />
          </Container>
        </div>
        <CallPrompt
          isVisible={callPrompt.isVisible}
          mode={callPrompt.mode}
          loaderModeConfig={{
            text: callPrompt.loaderText,
          }}
          alertModeConfig={{
            text: callPrompt.errorText,
          }}
          inputModeConfig={{
            onSubmit: this._onUserNameSubmit,
            roomName: roomName,
          }}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: (text) => dispatch(showLoader(text)),
    hideLoader: () => dispatch(hideLoader()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WebCall);
