import { store } from "../redux/store";
import { clearUserData } from "../redux/actions/user-data";
import * as _deepClone from "clone-deep";
import cogoToast from 'cogo-toast';

export const logout = (navRef) => {
  if (navRef) {
    navRef.replace("/login");
  }
  store.dispatch(clearUserData());
};

export const deepClone = (data) => {
  return _deepClone(data);
};

export const sleepTime = (n) => new Promise((r) => setTimeout(() => r(), n));

export const scrollToTop = () => {
  window.scrollTo(0, 0);
};

export const showToast = (message, type = "error", options = {
  position: 'top-center'
}) => {
  cogoToast[type](message, options);
};

export const navigateToRoute = (
  navRef,
  route,
  method = "push",
  clickEvent = null
) => {
  if (clickEvent) {
    clickEvent.preventDefault();
  }
  if (navRef) {
    navRef[method](route);
  }
};

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const doesArraysContainSameSetOfValues = (_arr1, _arr2) => {
  if (
    !Array.isArray(_arr1) ||
    !Array.isArray(_arr2) ||
    _arr1.length !== _arr2.length
  )
    return false;

  let arr1 = _arr1.concat().sort();
  let arr2 = _arr2.concat().sort();

  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) return false;
  }

  return true;
};

export const extractQueryParams = () => {
  let {
    location: { search: queryParamString },
  } = window;
  let params = {};
  if (queryParamString.length > 1 && queryParamString.indexOf("?") > -1) {
    queryParamString = queryParamString.replace("?", "");
    if (queryParamString.indexOf("&") === -1) {
      // Contains only one param
      const paramParts = queryParamString.split("=");
      params[paramParts[0]] = paramParts[1];
    } else {
      // Contains multiple params
      const queryParams = queryParamString.split("&");
      queryParams.forEach((queryParam) => {
        const paramParts = queryParam.split("=");
        params[paramParts[0]] = paramParts[1];
      });
    }
  }
  return params;
};

export const getMapSize = (x) => {
  var len = 0;
  for (var count in x) {
    len++;
  }
  return len;
};
