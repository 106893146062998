import React, { useEffect } from "react";
import "./waiting-screen.scss";

const WaitingScreen = (props) => {
  // Default props
  const {
    mediaUrl = "https://res.cloudinary.com/dau24vn1u/video/upload/v1607087422/telehealth_assets/joining_video_online-video-cutter.com_vdzuil.mp4",
    audioSource = "",
    isAudioEnabled = true,
    isVideoEnabled = true,
    joiningMessage = "Video conference will begin soon",
    disableMedia = false
  } = props;

  useEffect(() => {
    // On Mount

    return () => {
      // On unmount
    };
  }, []);

  return (
    <>
      <div id="waitingScreenWrapper">
        <div className="messageWrapper">{joiningMessage}</div>
        {
          !disableMedia? (
            <video
              width={320}
              height={240}
              autoplay="autoplay"
              loop
              controls={null}
            >
              <source src={mediaUrl} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          ): <></>
        }
      </div>
    </>
  );
};

export default WaitingScreen;