import {
  makeGetRequest,
  makeGetRequestNonJSON,
  makePostRequest,
  uploadFile,
} from "../http-connectors";
const qs = require("querystring");

export const getVideoCallToken = (identity, roomName) => {
  return new Promise((resolve, reject) => {
    makeGetRequestNonJSON(
      `${process.env.REACT_APP_TOKEN_SERVER_BASE_URL}/videoToken?identity=${identity}&roomName=${roomName}`,
      false
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("getVideoCallToken error: ", e);
        reject(e);
      });
  });
};

export const getChatToken = (userName) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(
      `${process.env.REACT_APP_TOKEN_SERVER_BASE_URL}/chatToken/${userName}`,
      false
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("getChatToken error: ", e);
        reject(e);
      });
  });
};

export const cloudinaryImageUpload = (imageData) => {
  return new Promise((resolve, reject) => {
    uploadFile(
      `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDINARY_API_KEY}/image/upload?upload_preset=${process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET}`,
      false,
      imageData
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const cloudinaryAutoUpload = (fileData) => {
  const cloudinaryConfig = {
    APIKey: "dau24vn1u",
    uploadPreset: "fm39f0xu",
  };
  return new Promise((resolve, reject) => {
    uploadFile(
      `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDINARY_API_KEY}/auto/upload?upload_preset=${process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET}`,
      false,
      fileData
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getTwilioCreds = (authencode) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(
      `${process.env.REACT_APP_AZURE_BASE_URL}/videosessionparams/${authencode}?code=${process.env.REACT_APP_AZURE_CODE}`,
      false
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("getTwilioCreds error: ", e);
        reject(e);
      });
  });
};

export const getDynamicVideoCallToken = (identity, configs) => {
  return new Promise((resolve, reject) => {
    makePostRequest(
      `${process.env.REACT_APP_TOKEN_SERVER_BASE_URL}/dynamicVideoToken`,
      false,
      {
        identity,
        configs,
      }
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("getDynamicVideoCallToken error: ", e);
        reject(e);
      });
  });
};

export const getDynamicChatToken = (identity, configs) => {
  return new Promise((resolve, reject) => {
    makePostRequest(
      `${process.env.REACT_APP_TOKEN_SERVER_BASE_URL}/dynamicChatToken`,
      false,
      {
        identity,
        configs,
      }
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("getDynamicChatToken error: ", e);
        reject(e);
      });
  });
};

export const uploadMedia = (fileData) => {
  return new Promise((resolve, reject) => {
    uploadFile(
      `${process.env.REACT_APP_TOKEN_SERVER_BASE_URL}/uploadMedia`,
      false,
      fileData
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const expireToken = (authcode) => {
  return new Promise((resolve, reject) => {
    makePostRequest(
      `${process.env.REACT_APP_AZURE_BASE_URL}/user/expirevideoauthencode`,
      false,
      { authcode }
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const fetchRoomNumber = (authcode) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(
      `${process.env.REACT_APP_AZURE_BASE_URL}/twiliovideonumber/${authcode}`,
      false,
      { authcode }
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const setRoomNumber = (authcode, number) => {
  return new Promise((resolve, reject) => {
    makePostRequest(
      `${process.env.REACT_APP_AZURE_BASE_URL}/twiliovideonumber/${authcode}/${number}?code=9aAPmsRRadsfyjfhrzYDFWocUAxByxMFVZY8/rAeSAn7Ne05APDX2w==&clientId=dt_key_stag`,
      false,
      { authcode }
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const setRoomId = (authcode, roomId) => {
  return new Promise((resolve, reject) => {
    makePostRequest(
      `${process.env.REACT_APP_AZURE_BASE_URL}/twiliovideoroomid/${authcode}/${roomId}?code=9aAPmsRRadsfyjfhrzYDFWocUAxByxMFVZY8/rAeSAn7Ne05APDX2w==&clientId=dt_key_stag`,
      false,
      { authcode }
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};
