import React, { Component } from "react";
import Modal from "react-awesome-modal";
import "./call-prompt.scss";
import Loader from "react-loader-spinner";

class CallPrompt extends Component {
  state = {
    inputValue: "",
    errorText: "",
  };

  _onModalDismiss = () => {
    if (this.props.dismiss) {
      this.props.dismiss();
    }
  };

  _getModalHeight = () => {
    const { mode } = this.props;
    switch (mode) {
      case "alert": {
        return "200";
      }
      default: {
        return "350";
      }
    }
  };

  _onInputChange = (inputValue) => {
    this.setState({ inputValue });
  };

  _onSubmit = () => {
    const { inputValue } = this.state;
    const {
      inputModeConfig: { onSubmit = (inputValue) => {} },
    } = this.props;

    if (inputValue.trim().length === 0) {
      this.setState({ errorText: "Name cannot be empty" });
    } else {
      onSubmit(inputValue.trim());
    }
  };

  _renderView = () => {
    const {
      mode = "loader",
      loaderModeConfig = {
        text: "Please wait",
      },
      alertModeConfig = {
        text: "You are not authorised to join",
      },
      inputModeConfig,
    } = this.props;
    const { inputValue, errorText } = this.state;
    switch (mode) {
      case "loader": {
        return (
          <div className="loaderView">
            <Loader type="Rings" color="black" />
            <h4>{loaderModeConfig.text}</h4>
          </div>
        );
      }
      case "alert": {
        return (
          <div className="errorPromptView">
            <div className="iconWrapper">
              <div className="icon">
                <i
                  className="fa fa-exclamation-triangle"
                  aria-hidden="true"
                ></i>
              </div>
            </div>
            <div className="headerText">{alertModeConfig.text}</div>
          </div>
        );
      }
      case "input": {
        return (
          <div className="userPromptView">
            <div className="iconWrapper">
              <div className="icon">
                <i className="fa fa-video-camera" aria-hidden="true"></i>
              </div>
            </div>
            <div className="headerText">Room: {decodeURIComponent(inputModeConfig.roomName)}</div>
            <form
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <div className="inputWrapper">
                <input
                  type="text"
                  placeholder="Please enter your name to join"
                  autoFocus
                  value={inputValue}
                  onChange={(e) => this._onInputChange(e.target.value)}
                />
              </div>
              <div className="errorText">{errorText}</div>
              <button
                className="actionBar"
                type="submit"
                onClick={(e) => this._onSubmit()}
              >
                Join &nbsp;{" "}
                <i className="fa fa-chevron-right" aria-hidden="true"></i>
              </button>
            </form>
          </div>
        );
      }
    }
  };

  render() {
    const { isVisible = true } = this.props;

    return (
      <>
        {isVisible ? (
          <Modal
            visible={isVisible}
            width="500"
            height={this._getModalHeight()}
            // height="200"
            effect="fadeInUp"
            onClickAway={() => this._onModalDismiss()}
          >
            <div className="callPromptWrapper">{this._renderView()}</div>
          </Modal>
        ) : (
          <></>
        )}
      </>
    );
  }
}

export default CallPrompt;
