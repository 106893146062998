import React, { Component } from "react";
import "./404-page.scss";

class Page404 extends Component {
  state = {};
  render() {
    return (
      <>
        <div className="wrapper404">
          <img
            src="https://lageniusinfo.com/wp-content/uploads/2018/09/404-735x465.png"
            alt=""
          />
        </div>
      </>
    );
  }
}

export default Page404;
