import React, { Component } from "react";
import "./session-manager.scss";
import Loader from "react-loader-spinner";
import { deepClone, sleepTime, showToast } from "../../helper-methods";
import { expireToken, setRoomNumber, setRoomId } from "../../http-calls";
import Modal from "react-awesome-modal";

class SessionManager extends Component {
  state = {
    isCounterInitialized: false,
    timeRemaining: 600, //  10 mins
    isPanelVisible: false,
    isStartingSession: false,
    roomNumber: "",
  };

  counterRef = null;

  async componentDidMount() {
    this._manageSession();
    await this._setRoomNumber();
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
      this._manageSession();
    } else if (
      prevProps.remoteParticipants.length !== this.props.remoteParticipants
    ) {
      this._manageSession();
    }
  }

  _setRoomNumber = () => {
    try {
      const roomNumber = this._generateRandomNumber();
      this.setState({ roomNumber }, async () => {
        try {
          const { authenCode, roomName } = this.props;
          await setRoomNumber(authenCode, roomNumber);
          await setRoomId(authenCode, roomName);
        } catch (error) {}
      });
    } catch (error) {}
  };

  _generateRandomNumber = (n = 4) => {
    var add = 1,
      max = 12 - add; // 12 is the min safe number Math.random() can generate without it starting to pad the end with zeros.

    if (n > max) {
      return (
        this._generateRandomNumber(max) + this._generateRandomNumber(n - max)
      );
    }

    max = Math.pow(10, n + add);
    var min = max / 10; // Math.pow(10, n) basically
    var number = Math.floor(Math.random() * (max - min + 1)) + min;

    return ("" + number).substring(add);
  };

  _manageSession = () => {
    let { isCounterInitialized, isPanelVisible } = deepClone(this.state);
    const {
      isSessionStarted,
      isCallInitialized,
      isHost,
      remoteParticipants,
    } = deepClone(this.props);
    // Check if counter is not initialized
    if (!isCounterInitialized) {
      if (
        isCallInitialized &&
        !isSessionStarted &&
        isHost &&
        remoteParticipants &&
        remoteParticipants.length &&
        remoteParticipants.length > 1
      ) {
        isCounterInitialized = true;
        isPanelVisible = true;
        this.setState({ isCounterInitialized, isPanelVisible }, () => {
          this._initializeCounter();
        });
      }
    }
  };

  _initializeCounter = () => {
    this.counterRef = setInterval(() => {
      let { timeRemaining } = this.state;
      if (timeRemaining > 0) {
        timeRemaining--;
        this.setState({ timeRemaining });
      } else {
        // Time up

        clearInterval(this.counterRef);
      }
    }, 1000);
  };

  _startSession = () => {
    this.setState({ isStartingSession: true }, async () => {
      try {
        const { authenCode } = this.props;
        await expireToken(authenCode);
        this.setState({ isPanelVisible: false });
        showToast("Session is active now", "success");
      } catch (error) {
        showToast("Session is active now", "success");
        this.setState({ isPanelVisible: false });
      }
    });
  };

  _secondsToMinutes = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    let formattedString = "";
    if (minutes > 0) {
      formattedString = `${minutes} min${minutes > 1 ? "s" : ""} `;
    }
    if (seconds > 0) {
      formattedString += `${seconds} second${seconds > 1 ? "s" : ""}`;
    }
    return formattedString;
  };

  render() {
    const {
      isCounterInitialized,
      timeRemaining,
      isPanelVisible,
      isStartingSession,
      roomNumber,
    } = this.state;
    const { remoteParticipants, roomLink } = this.props;
    return (
      <Modal
        visible={isPanelVisible}
        width="780"
        height="500"
        effect="fadeInUp"
        onClickAway={() => {}}
      >
        <div className="sessionInitiatorModalWrapper">
          {isStartingSession ? (
            <div className="joiningScreen">
              <div className="leftPart">Starting session</div>
              <div className="rightPart">
                <Loader type="Rings" color="white" />
              </div>
            </div>
          ) : (
            <>
              <div className="joinerScreen">
                <div className="callJoinWrapper">
                  <div className="joiningHeader">
                    <img
                      src={require("../../assets/images/phone-icon.jpg")}
                      alt=""
                      className="joinerLogo"
                    />
                    <h4>Invite via voice call</h4>
                  </div>
                  <div className="joiningText">
                    <p>Ask the users to make a call to the following number</p>
                    <p className="highlightedText">+1 833 549 1358</p>
                    <p>And enter the room number once prompted</p>
                    <p className="highlightedText">{roomNumber}</p>
                  </div>
                </div>
                <div className="webJoinWrapper">
                  <div className="joiningHeader">
                    <img
                      src={require("../../assets/images/video-call-icon.png")}
                      alt=""
                      className="joinerLogo"
                    />
                    <h4>Invite via video call</h4>
                  </div>
                  <div className="joiningText">
                    <p>Share the following link to let users join</p>
                    <p className="highlightedText shareLinkText">{roomLink}</p>
                  </div>
                </div>
              </div>
              <div className="sessionHelper">
                <div className="leftPart">
                  <p className="timeCounter">
                    Session will start in{" "}
                    {this._secondsToMinutes(timeRemaining)}
                  </p>
                  <p className="patientsJoined">
                    Patients joined:{" "}
                    {remoteParticipants && remoteParticipants.length
                      ? remoteParticipants.length - 1
                      : 0}
                  </p>
                </div>
                <div className="rightPart">
                  <button
                    className="sessionStartBtn"
                    onClick={this._startSession}
                  >
                    Start
                    <br />
                    Session
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
      </Modal>
    );
  }
}

export default SessionManager;
