import React, { useEffect, useState } from "react";
import "./post-call-page.scss";
import { extractQueryParams } from "../../helper-methods";

const PostCallPage = (props) => {
  const [redirectUrl, setRedirectUrl] = useState(null);
  useEffect(() => {
    const urlParams = extractQueryParams();
    if (urlParams.redirectUrl) {
      setRedirectUrl(urlParams.redirectUrl);
    }
    setTimeout(() => {
      if (urlParams.redirectUrl) {
        _redirectNow(urlParams.redirectUrl);
      }
    }, 3000);
  }, []);
  const _redirectNow = (url) => {
    window.location = url;
  };
  if (redirectUrl && redirectUrl.length) {
    return (
      <>
        <div id="postCallPageWrapper">
          <div id="postCallPageInnerWrapper">
            <h3>You are being redirected, please wait...</h3>
            <h4>
              If you haven't being redirected in 30 seconds, please click this
              button
            </h4>
            <button onClick={(e) => _redirectNow(redirectUrl)}>Continue</button>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div id="postCallPageWrapper">
          <div id="postCallPageInnerWrapper">
            <h3>Thank you</h3>
            <h4>The session has ended. Please close the tab.</h4>
          </div>
        </div>
      </>
    );
  }
};

export default PostCallPage;
